import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";
import {
  useAcceptReserveRequest,
  useGetReservedHospitals,
} from "../../../../hooks/agent/useAgent";
import { Button } from "../../../../@/components/ui/button";

const filterStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "accepted",
    label: "Accepted",
  },

  {
    value: "pending",
    label: "Pending",
  },
];
const ReservedList = (): JSX.Element => {
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || ""
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const filters = {
    user_id: user?.id as string,
    limit: pageSize,
    page: currentPage,
    name: search,
    status: status,
  };
  const { reservedHospitals, isPending, paginate } =
    useGetReservedHospitals(filters);
  const { mutateAcceptReserveRequest, isPending: isAccepting } =
    useAcceptReserveRequest();

  const onAcceptReserveRequest = (itemId: string) => {
    const payload = {
      id: itemId,
      admin_id: user?.id,
    };
    mutateAcceptReserveRequest(payload);
  };
  const [clickedRowId, setClickedRowId] = useState<number | string | null>(
    null
  );
  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return <> {hospital?.name ?? "N/A"}</>;
      case "tym":
        const date = hospital[column.accessor]
          ? new Date(hospital[column.accessor] * 1000)
          : null;

        return (
          <span>
            {date
              ? `${date.getDate().toString().padStart(2, "0")}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date.getFullYear()}`
              : "N/A"}
          </span>
        );
      case "status":
        return (
          <span
            style={{
              backgroundColor:
                hospital[column.accessor] === "accepted"
                  ? "#4CAF50"
                  : hospital[column.accessor] === "good"
                  ? "#3B82F6"
                  : hospital[column.accessor] === "close"
                  ? "#f44336"
                  : hospital[column.accessor] === "pending"
                  ? "#FFD700"
                  : "#f44336",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );
      case "action":
        return (
          <>
            {hospital.status !== "accepted" && (
              <Button
                disabled={isAccepting}
                onClick={() => {
                  onAcceptReserveRequest(hospital.id);
                  setClickedRowId(hospital.id);
                }}
              >
                {isAccepting && hospital.id === clickedRowId
                  ? "Accepting..."
                  : "Accept"}
              </Button>
            )}
          </>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Assigned", accessor: "reassignment_count" },
    { header: "Status", accessor: "status" },
    { header: "Date", accessor: "created_at", className: "text-nowrap" },

    { header: "Agent", accessor: "username", className: "max-w-32 w-32" },
    { header: "", accessor: "action" },
  ];
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleStatusChange = (status: React.SetStateAction<string>) => {
    setStatus(status);
    navigate(`?status=${status}&page=${currentPage}`, {
      replace: true,
    });
  };

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      pathname
    );
  };
  return (
    <React.Fragment>
      <Header title="Reserved Hospitals" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => handleSearch(e.currentTarget.value)}
            />

            <div className="right-options">
              <ReactSelect
                placeholder="Filter by Status"
                // defaultValue={status}
                options={filterStatus?.map(
                  (status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  })
                )}
                onChange={handleStatusChange}
              />
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={reservedHospitals ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={
                  "You Currently don't have any Reserved hospitals"
                }
              />
            </>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ReservedList;
