import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../../components/blocks/header";
import RadioButton from "../../../../../components/common/radio";

import toastService from "../../../../../services/toastService";
import Modal from "../../../../../components/common/modal";
import LoadingButton from "../../../../../components/common/loadingButton";
import ProgressLoader from "../../../../../components/common/progressLoader";
import Select from "../../../../../components/common/select";
import { ReactComponent as UploadIcon } from "../../../../../assets/svg/upload.svg";
import LogVisit from "../../../../../components/modals/logVisit";

import {
  useCloseAppointment,
  useGetSinglePendingRequest,
} from "../../../../../hooks/sales/useAppointment";

import appointmentService from "../../../../../services/appointmentService";
import { Button } from "../../../../../@/components/ui/button";
import OptimizedRequestInfo from "./optimized";

interface Summary {
  feedback: string;
  pitch_note: string;
}

interface LogData {
  location: string;
}

interface Checklist {
  Nerve: { status: string };
}

const RequestInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const request_id = searchParams.get("id");

  const { pitchInfo, isPending } = useGetSinglePendingRequest(
    request_id as string
  );

  const [formState, setFormState] = useState({
    meetingSummary: "",
    offerReason: "",
    summary: null as Summary | null,
    progress: 0,
    page: 1,
    statusType: "",
    offerType: "",
    offerList: "",
    objectionType: "",
    logData: null as LogData | null,
    offerFile: null as File | null,
    offerName: "",
    slaFile: null as File | null,
    slaName: "",
    address: null as string | null,
    onboard: "",
    needCredit: "",
  });

  const [checklist, setChecklist] = useState<Checklist | null>(null);

  const updateFormState = useCallback((updates: Partial<typeof formState>) => {
    console.log(updates);
    setFormState((prev) => ({ ...prev, ...updates }));
  }, []);

  useEffect(() => {
    localStorage.setItem("skippedLog", pitchInfo.pitch_method);
    localStorage.setItem("lastname", JSON.stringify(pitchInfo));
    localStorage.setItem("name", JSON.stringify(pitchInfo.id));
  }, [pitchInfo]);

  const { mutate } = useCloseAppointment();

  const isReserved =
    formState.offerType === "No" || formState.objectionType === "No";
  //  ||
  // checklist?.Nerve?.status === "No";

  const postData = async () => {
    const data = new FormData();

    if (formState.statusType === "Yes") {
      data.append("summary", formState.meetingSummary);
      data.append("grade", formState.onboard);
      data.append("key", formState.statusType);
      data.append("id", pitchInfo.id);
      data.append("needcredit", formState.needCredit);
      if (formState.slaFile) data.append("sla", formState.slaFile);
      data.append("credit", formState.needCredit);
      data.append("location", formState.logData?.location as string);
    } else if (
      formState.statusType === "Maybe" &&
      formState.offerType === "Yes"
    ) {
      data.append("summary", formState.meetingSummary);
      data.append("grade", formState.onboard);
      data.append("key", formState.statusType);
      data.append("id", pitchInfo.id);
      data.append("needcredit", formState.needCredit);
      if (formState.slaFile) data.append("sla", formState.slaFile);
      data.append("location", formState.logData?.location as string);
      if (formState.offerFile) data.append("offer", formState.offerFile);
      data.append("offer_type", formState.offerList);
      data.append("offer_status", formState.offerType);
    } else if (
      formState.offerType === "No" &&
      formState.statusType === "Maybe"
    ) {
      data.append("summary", formState.meetingSummary);
      data.append("grade", formState.onboard);
      data.append("key", formState.statusType);
      data.append("id", pitchInfo.id);
      data.append("location", formState.logData?.location as string);
      data.append("offer_status", formState.offerType);
      data.append("reason", formState.offerReason);
    } else if (
      formState.objectionType === "No" &&
      formState.statusType === "No"
    ) {
      data.append("summary", formState.meetingSummary);
      data.append("grade", formState.onboard);
      data.append("key", formState.statusType);
      data.append("id", pitchInfo.id);
      data.append("location", formState.logData?.location as string);
    } else if (
      formState.objectionType === "Yes" &&
      formState.statusType === "No" &&
      formState.offerType === "Yes"
    ) {
      data.append("summary", formState.meetingSummary);
      data.append("grade", formState.onboard);
      data.append("key", formState.statusType);
      data.append("id", pitchInfo.id);
      data.append("need_credit", formState.needCredit);
      if (formState.slaFile) data.append("sla", formState.slaFile);
      data.append("location", formState.logData?.location as string);
      if (formState.offerFile) data.append("offer", formState.offerFile);
      data.append("offer_type", formState.offerList);
      data.append("offer_status", formState.offerType);
    } else if (
      formState.objectionType === "Yes" &&
      formState.statusType === "No" &&
      formState.offerType === "No"
    ) {
      data.append("summary", formState.meetingSummary);
      data.append("grade", formState.onboard);
      data.append("key", formState.statusType);
      data.append("id", pitchInfo.id);
      data.append("location", formState.logData?.location as string);
      data.append("offer_status", formState.offerType);
      data.append("reason", formState.offerReason);
    }
    // setPage(4);
    // navigate(`/checklist/${pitchInfo.id}`);
    if (checklist?.Nerve?.status === "No") {
      navigate("/pipeline?content=close");
    } else {
      mutate(data, {
        onSuccess: () => {
          navigate("/pipeline?content=close");
          // if (formState.offerType !== "No") {
          //   updateFormState({ page: 4 });
          // }
          // if (isReserved) {
          //   navigate("/pipeline?content=close");
          // }
        },
      });
    }
  };

  useEffect(() => {
    const getChecklist = async () => {
      try {
        const response = await appointmentService.getOnboardChecklist(
          pitchInfo.hospital_id
        );
        setChecklist(response.data?.data);
      } catch (error) {
        console.error("Failed to fetch checklist:", error);
      }
    };
    if (pitchInfo.hospital_id) {
      getChecklist();
    }
  }, [pitchInfo.hospital_id]);

  useEffect(() => {
    const fetchLocationDetails = async (
      latitude: string,
      longitude: string
    ) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAg7-YYR9clrIdYVCILJ0zZvL6qDquZLYw`
        );
        const data = await response.json();
        if (data.status === "OK" && data.results.length > 0) {
          updateFormState({ address: data.results[0].formatted_address });
        }
      } catch (error) {
        console.error("Failed to fetch location details:", error);
      }
    };

    const [latitude, longitude] = (pitchInfo.location ?? ",").split(",");
    fetchLocationDetails(latitude, longitude);
  }, [pitchInfo.location, updateFormState]);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toastService.promise(postData(), {
      loading: "Uploading data...",
      success: () =>
        formState.offerType === "No" || formState.objectionType === "No"
          ? `${pitchInfo.name} has been closed and moved to the reserved list`
          : `Data uploaded successfully, Please create active schedule for ${pitchInfo.name}`,
      error: (e: any) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not process this information!",
    });
  };
  const handleSlaChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        updateFormState({
          slaName: file.name,
          slaFile: file,
        });
      }
    },
    [updateFormState]
  );

  const handleOfferUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        updateFormState({
          offerName: file.name,
          offerFile: file,
        });
      }
    },
    [updateFormState]
  );
  const renderPage = () => {
    switch (formState.page) {
      case 1:
        return (
          <div className="pitch-main">
            <div className="pitch-data2">
              <span className="label">Hospital Name:</span>
              <span className="value">{pitchInfo.name}</span>
            </div>

            <div className="pitch-data2">
              <span className="label">Agent:</span>
              <span className="value">{pitchInfo.agent_name}</span>
            </div>
            <div className="pitch-data2">
              <span className="label">Location:</span>
              <span className="value">{pitchInfo.address}</span>
            </div>
            <div className="pitch-data2">
              <span className="label">Pitch meeting summary</span>
              <span
                className="value link"
                onClick={() =>
                  updateFormState({
                    summary: {
                      feedback: pitchInfo.feedback,
                      pitch_note: pitchInfo.pitch_note,
                    },
                  })
                }
              >
                view
              </span>
            </div>
            <div className="pitch-data2">
              <span className="label">Pitch location</span>
              <span className="value">{formState.address}</span>
            </div>

            <div className="pitch-data2">
              <span className="label">Status</span>
              <span className="value">{pitchInfo.onboard}</span>
            </div>

            <div className="pitch-data2">
              <span className="label">Closing Date</span>
              <span className="value">{`${pitchInfo.visit_date} | ${pitchInfo.visit_time} `}</span>
            </div>

            <div className="flex items-center justify-center mt-3">
              <button
                className="custom-btn transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7]  transform hover:-translate-y-1 hover:scale-110 px-4 rounded-md py-2"
                onClick={() => updateFormState({ page: 2 })}
              >
                Log your location
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex flex-col justify-center items-center min-w-[70%] min-h-[70vh]">
            <LogVisit
              handleClose={() => updateFormState({ page: 1 })}
              changePage={() => updateFormState({ page: 3 })}
              setLogData={(logData: any) => updateFormState({ logData })}
            />
          </div>
        );
      case 3:
        return (
          <div className="pitch-main">
            <div className="pitch-data2">
              <span className="label">Grade Self</span>{" "}
              {/* <span className="label">Grade your agent</span> */}
              <div className="value">
                <RadioButton
                  name="1"
                  value="1"
                  message="1"
                  onChange={() => updateFormState({ onboard: "1" })}
                  checked={formState.onboard === "1"}
                />
                <RadioButton
                  name="2"
                  value="2"
                  message="2"
                  onChange={() => updateFormState({ onboard: "2" })}
                  checked={formState.onboard === "2"}
                />
                <RadioButton
                  name="3"
                  value="3"
                  message="3"
                  onChange={() => updateFormState({ onboard: "3" })}
                  checked={formState.onboard === "3"}
                />
                <RadioButton
                  name="4"
                  value="4"
                  message="4"
                  onChange={() => updateFormState({ onboard: "4" })}
                  checked={formState.onboard === "4"}
                />
                <RadioButton
                  name="5"
                  value="5"
                  message="5"
                  onChange={() => updateFormState({ onboard: "5" })}
                  checked={formState.onboard === "5"}
                />
              </div>
            </div>

            <div className="pitch-data2">
              <span className="label">Close Meeting Summary</span>
              <textarea
                id="summary"
                name="summary"
                onChange={(e) =>
                  updateFormState({ meetingSummary: e.target.value })
                }
                placeholder="Meeting Summary"
                rows={3}
                cols={50}
                className="form-field-input-text outline p-1 rounded-md w-full outline-gray-300"
              />
            </div>

            <div className="pitch-data2">
              <span className="label">Would they like to use LifeBank?</span>
              <div className="right-options">
                <Select
                  placeholder="Select Status"
                  options={statusTypes}
                  value={formState.statusType}
                  onChange={(e: { target: { value: string } }) =>
                    updateFormState({
                      statusType: e.target.value,
                      objectionType: "",
                      offerType: "",
                    })
                  }
                />
              </div>
            </div>

            {formState.statusType === "No" && (
              <div className="pitch-data2">
                <span className="label">Did the objection sheet help?</span>
                <div className="value">
                  <Select
                    placeholder="Select "
                    options={objectionTypes}
                    value={formState.objectionType}
                    onChange={(e: { target: { value: string } }) =>
                      updateFormState({
                        objectionType: e.target.value,
                        offerType: "",
                      })
                    }
                  />
                </div>
              </div>
            )}

            {formState.statusType === "Yes" && (
              <div className="pitch-data2">
                <span className="label">Do you need Credit?</span>
                <div className="right-options">
                  <Select
                    placeholder="Do you need Credit?"
                    options={offerTypes}
                    value={formState.needCredit}
                    onChange={(e: { target: { value: string } }) =>
                      updateFormState({ needCredit: e.target.value })
                    }
                  />
                </div>
              </div>
            )}
            {formState.statusType === "Yes" &&
              formState.needCredit === "Yes" && (
                <div className="pitch-data2">
                  <>
                    <span className="label">Upload SLA</span>
                    <div className="value">
                      <div className="upload">
                        <label htmlFor="Image" className="upload-label">
                          {formState.slaFile ? (
                            <img
                              src={URL.createObjectURL(formState.slaFile)}
                              alt={formState.slaName}
                            />
                          ) : (
                            <>
                              <UploadIcon className="upload-icon" />
                            </>
                          )}
                        </label>
                        <input
                          name="sla"
                          type="file"
                          id="SLA"
                          accept=".jpeg, .png, .jpg, .pdf"
                          className="upload-input"
                          onChange={handleSlaChange}
                        />
                      </div>
                    </div>
                  </>
                </div>
              )}

            {(formState.statusType === "Maybe" ||
              formState.objectionType === "Yes") && (
              <div className="pitch-data2">
                <span className="label">Did they accept your offer?</span>
                <div className="value">
                  <Select
                    placeholder="Select Status"
                    options={offerTypes}
                    value={formState.offerType}
                    onChange={(e: { target: { value: string } }) =>
                      updateFormState({ offerType: e.target.value })
                    }
                  />
                </div>
              </div>
            )}

            {formState.offerType === "No" && (
              <div className="pitch-data2">
                <span className="label">Why did they say "No"?</span>
                <textarea
                  id="reason"
                  name="reason"
                  onChange={(e: { target: { value: string } }) =>
                    updateFormState({ offerReason: e.target.value })
                  }
                  placeholder="Reason for rejecting the offer"
                  rows={4}
                  cols={50}
                  className="form-field-input-text outline p-2 rounded-md w-full outline-gray-300"
                />
              </div>
            )}

            {formState.offerType === "Yes" &&
              formState.statusType !== "Yes" && (
                <div className="pitch-data2">
                  <span className="label">
                    Select offer and upload evidence
                  </span>
                  <div className="value">
                    <Select
                      placeholder="Select Offer"
                      options={offerLists}
                      value={formState.offerList}
                      onChange={(e: { target: { value: string } }) =>
                        updateFormState({ offerList: e.target.value })
                      }
                    />

                    <div>
                      <div className="upload">
                        <label htmlFor="Image" className="upload-label">
                          {formState.offerFile ? (
                            <img
                              src={URL.createObjectURL(formState.offerFile)}
                              alt={formState.offerName}
                            />
                          ) : (
                            <>
                              <UploadIcon className="upload-icon" />
                            </>
                          )}
                        </label>
                        <input
                          name="offer"
                          type="file"
                          id="Offer"
                          accept=".jpeg, .png, .jpg, .pdf"
                          className="upload-input"
                          onChange={handleOfferUpload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {formState.statusType !== "Yes" &&
              formState.offerType === "Yes" && (
                <>
                  <div className="pitch-data2">
                    <span className="label">Do you need Credit?</span>
                    <div className="right-options">
                      <Select
                        placeholder="Do you need Credit?"
                        options={offerTypes}
                        value={formState.needCredit}
                        onChange={(e: { target: { value: string } }) =>
                          updateFormState({ needCredit: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  {formState.needCredit === "Yes" && (
                    <div className="pitch-data2">
                      <span className="label">Upload SLA</span>
                      <div className="value">
                        <div className="upload">
                          <label htmlFor="Image" className="upload-label">
                            {formState.slaFile ? (
                              <img
                                src={URL.createObjectURL(formState.slaFile)}
                                alt={formState.slaName}
                              />
                            ) : (
                              <>
                                <UploadIcon className="upload-icon" />
                              </>
                            )}
                          </label>
                          <input
                            name="sla"
                            type="file"
                            id="SLA"
                            accept=".jpeg, .png, .jpg, .pdf"
                            className="upload-input"
                            onChange={handleSlaChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            <div className="custom-buttons mx-auto py-3">
              <button
                className="custom-btn transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] py-2 px-4 rounded-md active:translate-y-2 active:scale-95 disabled:bg-gray-400"
                // onClick={handleClose}
                onClick={() => updateFormState({ page: 4 })}
                disabled={
                  !formState.meetingSummary ||
                  !formState.onboard ||
                  !formState.statusType ||
                  (formState.statusType === "No" && !formState.objectionType) ||
                  formState.needCredit === "Yes" ||
                  (formState.statusType === "No" &&
                    formState.objectionType === "Yes") ||
                  (formState.statusType === "No" &&
                    formState.offerType === "No" &&
                    formState.offerReason === "" &&
                    formState.objectionType === "Yes")
                }
              >
                {isReserved
                  ? "Close and send to reserve list"
                  : "Proceed to check Nerve Status"}
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="pitch-data2 mx-auto justify-center">
            {/* <Nerve
              selectedPitch={pitchInfo}
              selectedResponse={checklist}
              handleNext={() => setPage(2)}
            />   */}
            {/* <ScheduleForm hospital_id={pitchInfo.hospital_id} width={true} /> */}
            <div className="flex flex-col max-w-lg">
              <div className="flex justify-between">
                <span className="label">
                  Does {pitchInfo?.name} have a Nerve account?
                </span>
                <div className="right-options flex items-center">
                  <span
                    className={
                      checklist?.Nerve?.status === "Yes" ? "nerve" : "no-nerve"
                    }
                  >
                    {checklist?.Nerve?.status}{" "}
                    <i
                      className={
                        checklist?.Nerve?.status === "Yes"
                          ? "fa fa-check yes"
                          : "fa fa-times no"
                      }
                    ></i>
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-2">
                {checklist?.Nerve?.status === "No" && (
                  <p>Please Sign Hospital Up for Nerve</p>
                )}

                <div>
                  <Button
                    onClick={(e) => {
                      handleClose(e);
                      // navigate("/pipeline?content=close");
                    }}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  if (isPending) {
    return <LoadingButton visible={true} message="Please wait" />;
  }
  return (
    <div>
      <OptimizedRequestInfo />
      
      {/* <ProgressLoader progress={formState.progress} />
      <div onClick={() => navigate(-1)} className="back">
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <Header
        title={`${formState.page === 4 ? "Check Nerve Usage " : ""}${
          pitchInfo?.name
        }`}
      />
      <div className="flex flex-col justify-center items-center w-full min-h-[70vh]">
        <form>{renderPage()}</form>
      </div> */}
      {/* {formState.summary && (
        <Modal handleClose={() => updateFormState({ summary: null })}>
          <div className="row">
            <div className="pitch-data2">
              <span className="label">Feedback:</span>
              <span className="value"> {formState.summary?.feedback}</span>
            </div>

            <div className="pitch-data2">
              <span className="label">Pitch Note:</span>
              <span className="value"> {formState.summary?.pitch_note}</span>
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
};

export default RequestInfo;

const statusTypes = [
  { id: "Yes", name: "Yes" },
  { id: "Maybe", name: "Maybe" },
  { id: "No", name: "No" },
];

const offerTypes = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" },
];

const offerLists = [
  { id: "Data Reward", name: "Data Reward" },
  { id: "Registration Reward", name: "Registration Reward" },
];

const objectionTypes = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" },
];
