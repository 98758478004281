import React, { useState } from "react";
import ProgressLoader from "../../../../../components/common/progressLoader";
import RadioButton from "../../../../../components/common/radio";
import Modal from "../../../../../components/common/modal";
import CustomDatePicker from "../../../../../components/common/datePicker";
import TimePicker from "../../../../../components/common/TimePicker";
import dayjs from "dayjs";

import appointmentService from "../../../../../services/appointmentService";
import { success, error, promise } from "../../../../../services/toastService";
import { useNavigate, useLocation } from "react-router-dom";
import LogVisit from "../../../../../components/modals/logVisit";
import { useGlobalContext } from "../../../../../contexts/globalContext";

import { useSendToClose } from "../../../../../hooks/sales/useAppointment";
import LogExpenses from "../../../../../components/modals/LogExpenses";

const PitchDetails = (props: { selectedPitch: any }) => {
  const { selectedPitch } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hospital_id = searchParams.get("id");

  const [page, setPage] = useState(1);
  const [onboard, setOnboard] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(
    selectedPitch?.date ? new Date(selectedPitch?.date) : new Date()
  );
  const [closingDate, setClosingDate] = useState<Date | null>(new Date());
  const [closingTime, setClosingTime] = useState();
  const [summary, setSummary] = useState("");
  const [selectedTime, setSelectedTime] = useState(selectedPitch?.time);
  const [progress, setProgress] = useState(0);
  const [editAppointment, setEditAppointment] = useState<{
    time: string;
    date: string;
  } | null>(null);
  const [log, setLog] = useState<{
    summary: string;
    successful: string;
    agent_id: string;
    hos_id: string;
    name: string;
    address: string;
  } | null>(null);
  const [logData, setLogData] = useState(
    {} as {
      location: string;
      address: string;
    }
  );
  const [createExpense, setCreateExpense] = useState(false);

  const { user } = useGlobalContext();

  const navigate = useNavigate();

  const handleOnboard = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setOnboard(e.target.value);
  };

  const handleProgressChange = (progress: React.SetStateAction<number>) => {
    setProgress(progress);
  };

  const viewAppointment = (
    value: React.SetStateAction<{
      time: string;
      date: string;
    } | null>
  ) => {
    setEditAppointment(value);
  };

  const handleLog = (
    value: React.SetStateAction<{
      summary: string;
      successful: string;
      agent_id: string;
      hos_id: string;
      name: string;
      address: string;
    } | null>
  ) => {
    setLog(value);
  };

  const handleStartDate = (startDate: React.SetStateAction<Date | null>) => {
    setStartDate(startDate);
  };

  const handleClosingDate = (
    closingDate: React.SetStateAction<Date | null>
  ) => {
    setClosingDate(closingDate);
  };

  const handleTimeChange = (e: { target: { value: any } }) => {
    setSelectedTime(e.target.value);
  };

  const handleClosingTime = (e: {
    target: { value: React.SetStateAction<undefined> };
  }) => {
    setClosingTime(e.target.value);
  };

  const { mutate, isPending } = useSendToClose(handleProgressChange);

  const newDate = async () => {
    let newData = {
      date: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
      time: selectedTime,
      hos_id: hospital_id,
    };

    const res = await appointmentService.updateAppointment(
      newData,
      handleProgressChange
    );
    if (res.data.status !== "success") throw new Error(res.data.message);
    if (res.data.status === "success") {
      setEditAppointment(null);
      navigate("/pipeline?content=pitches");
    }
  };

  const confirmDate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    promise(newDate(), {
      loading: "uploading data...",
      success: () => `Appointment date for ${selectedPitch.name} has been set!`,
      error: (e: { response: { data: { message: any } }; message: any }) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not update appointment!",
    });
  };

  const handleSendToClose = async () => {
    const payload = {
      summary: summary,
      successful: onboard,
      agent_id: user?.id,
      hos_id: hospital_id,
      location: logData.location,
      date: closingDate ? dayjs(closingDate).format("YYYY-MM-DD") : null,
      time: closingTime,
    };

    mutate(payload, {
      onSuccess: (data: any) => {
        success(data?.data?.message, "success");
        setCreateExpense(true);
        // navigate("/pipeline?content=pitches");
      },
      onError: (e: any) => {
        console.log(e);
        error(
          e?.response?.data?.message ||
            e?.message ||
            "Could not process this information!",
          "error"
        );
      },
    });
  };

  // const handleSendToClose = (e: { preventDefault: () => void }) => {
  //   e.preventDefault();
  //   promise(postData(), {
  //     loading: "Uploading data...",
  //     success: () =>
  //       "Thank you for adding your visit. It has been sent to close successfully!",
  //     error: (e: { response: { data: { message: any } }; message: any }) =>
  //       e?.response?.data?.message ||
  //       e?.message ||
  //       "Could not process this information!",
  //   });
  // };

  return (
    <React.Fragment>
      <ProgressLoader progress={progress} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "70vh",
        }}
      >
        {page === 1 && (
          <div className="pitch-main">
            <h4
              style={{
                textAlign: "center",
                marginTop: "1rem",
                textDecoration: "underline",
                fontWeight: "600",
              }}
            >
              PREPARE FOR PITCH
            </h4>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Hospital Name:
              </span>
              <span className="value">{selectedPitch?.name}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Phone Number:
              </span>
              <span className="value ">{selectedPitch?.phone}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Agent:
              </span>
              <span className="value ">{selectedPitch?.agent}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4 gap-3">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Location:
              </span>
              <span className="value ">{selectedPitch?.address}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Prospecting
              </span>
              <span className="label">{selectedPitch?.prospecting}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Appointment Details
              </span>
              <div className="value flex items-center">
                <span>{`${selectedPitch?.date ?? "-"} | ${
                  selectedPitch?.time ?? "-"
                } `}</span>
                <span
                  className="link"
                  onClick={() =>
                    viewAppointment({
                      time: selectedPitch?.time,
                      date: selectedPitch?.date,
                    })
                  }
                >
                  Edit
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Prep for Pitch
              </span>
              <div className=" value flex items-center">
                <p
                  className="link"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/presentation/d/1wjhLw9nOekEipmE1EcB3Ngd2IUwiw0jt/edit?usp=sharing&ouid=102254922731981175623&rtpof=true&sd=true",
                      "_blank"
                    )
                  }
                >
                  Conversational Deck
                </p>
                <p
                  className="link"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch?app=desktop&v=kGFPS2v-uwE",
                      "_blank"
                    )
                  }
                >
                  Video
                </p>
              </div>
            </div>

            <div className="custom-buttons mx-auto py-3 ">
              <button
                className="shadow-sm transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] transform hover:-translate-y-1 hover:scale-110 py-2 px-4 rounded-md"
                onClick={(e) => {
                  e.preventDefault();
                  handleLog({
                    summary: summary,
                    successful: onboard,
                    agent_id: user?.id as string,
                    hos_id: hospital_id as string,
                    name: selectedPitch?.name,
                    address: selectedPitch?.address,
                  });
                  setPage(2);
                }}
              >
                I'm ready
              </button>
            </div>
          </div>
        )}

        {page === 2 && log && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "70%",
            }}
          >
            <LogVisit
              handleClose={() => {
                setLog(null);
                setPage(1);
              }}
              changePage={() => setPage(3)}
              setLogData={setLogData}
              label="Good luck with your pitch. Proceed to submit your result"
            />
          </div>
        )}

        {page === 3 && (
          <div className="pitch-main">
            <h4
              style={{
                textAlign: "center",
                marginTop: "1rem",
                textDecoration: "underline",
                fontWeight: "600",
              }}
            >
              POST PITCH PAGE
            </h4>
            <div className="pitch-data2">
              <span className="label">Summary</span>
              <div className="value">
                <textarea
                  id="summary"
                  name="summary"
                  onChange={(e) => setSummary(e.target.value)}
                  placeholder="Provide information about objections and complaints"
                  rows={4}
                  // cols={50}
                  className="form-field-input-text outline p-2 rounded-md w-full outline-gray-300"
                ></textarea>
              </div>
            </div>

            <div className="pitch-data2">
              <span className="label">
                Would {selectedPitch?.name} like to use lifebank{" "}
              </span>
              <div className="value">
                <RadioButton
                  name="yes"
                  value="yes"
                  message="Yes"
                  onChange={handleOnboard}
                  checked={onboard === "yes"}
                />
                <RadioButton
                  name="no"
                  value="no"
                  message="No"
                  onChange={handleOnboard}
                  checked={onboard === "no"}
                />
                <RadioButton
                  name="maybe"
                  value="maybe"
                  message="Maybe"
                  onChange={handleOnboard}
                  checked={onboard === "maybe"}
                />
              </div>
            </div>

            <div className="pitch-data2">
              <span className="label">Closing Appointment </span>
              <div
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   gap: "0.5rem",
                //   // width: "100%",
                // }}
                className="value"
              >
                <div className="row">
                  <div className="col-md">
                    <CustomDatePicker
                      label="Appointment Date"
                      minDate={new Date()}
                      selectedDate={closingDate}
                      onDateChange={handleClosingDate}
                    />
                  </div>
                </div>

                <div className="col-md">
                  <TimePicker
                    label="Appointment Time"
                    name="time"
                    onChange={handleClosingTime}
                    value={closingTime}
                  />
                </div>
              </div>
            </div>
            <div className="custom-buttons mx-auto py-3">
              <button
                className={`shadow-md transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] py-2 px-4 rounded-md active:translate-y-2 active:scale-95 ${
                  !summary || !onboard || !closingDate || !closingTime
                    ? "disabled"
                    : ""
                }`}
                onClick={handleSendToClose}
                disabled={
                  !summary ||
                  !onboard ||
                  !closingDate ||
                  !closingTime ||
                  isPending
                }
              >
                Prep for Closing
              </button>
            </div>
          </div>
        )}
      </div>

      {editAppointment && (
        <Modal handleClose={() => setEditAppointment(null)}>
          <div className="row">
            <div className="col-lg">
              <CustomDatePicker
                label="Appointment Date"
                minDate={new Date()}
                selectedDate={startDate}
                onDateChange={handleStartDate}
              />
            </div>
          </div>

          <TimePicker
            label="Appointment Time"
            name="time"
            onChange={handleTimeChange}
            value={selectedTime}
          />
          <div className="pitch-buttons">
            <button className="status primary" onClick={confirmDate}>
              confirm
            </button>
          </div>
        </Modal>
      )}

      <LogExpenses
        note={summary}
        destinationGeoCode={logData?.location as string}
        destinationAddress={
          selectedPitch.address
            ? selectedPitch.address
            : logData?.address
            ? (logData?.address as string)
            : "N/A"
        }
        createExpense={createExpense}
        setCreateExpense={setCreateExpense}
      />
    </React.Fragment>
  );
};

export default PitchDetails;
