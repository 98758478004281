import React, { useCallback, useEffect, useMemo, useState } from "react";

import LoadingButton from "../../../../components/common/loadingButton";

import { useGlobalContext } from "../../../../contexts/globalContext";
import applyFilters from "../../../../helpers/urlState";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetFeed } from "../../../../hooks/useDashboard";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { getPrivileges } from "../../../../services/authService";
import { Hospital } from "../../../../ts-utils/types";
import Header from "../../../../components/blocks/header";

const ActivityFeed = ({ pageSize = 10 }: { pageSize?: number }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");
  const [currentPage, setCurrentPage] = useState(1);

  const privileges = getPrivileges();
  const { user } = useGlobalContext();
  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  // const [status, setStatus] = useState<string>(
  //   searchParams.get("status") || ""
  // );
  // const [search, setSearch] = useState(searchParams.get("search") || "");

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    // name: search,
    // status: status,
  };
  const { feed, isPending, paginate } = useGetFeed(filters);

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital.agent_username
              ? `${hospital.name} has been ${hospital.event}{" "}
            ${hospital?.event?.toLowerCase() === "assigned" ? "to" : "by"}{" "}
            ${hospital.agent_username}`
              : `${hospital.name} has been ${hospital.event}`}
          </>
        );
      case "tym":
        const date = hospital[column.accessor]
          ? new Date(hospital[column.accessor] * 1000)
          : null;

        return (
          <span>
            {date
              ? `${date.getDate().toString().padStart(2, "0")}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date.getFullYear()}`
              : "N/A"}
          </span>
        );
      case "status":
        return (
          <button
            disabled
            className={"status " + hospital?.status?.toLowerCase?.()}
          >
            {hospital.status}
          </button>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Activity Feed", accessor: "name" },
    { header: "Status", accessor: "status" },
    { header: "Date", accessor: "date", className: "text-nowrap" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );

  return (
    <div className={isPending ? "loading-background" : "activity-feed fade-in"}>
      <LoadingButton visible={isPending} message="Fetching Activities" />

      <Header title="History" isDashboard />
      <>
        <ProductTable
          data={feed ?? []}
          columns={columns}
          renderCell={renderCell}
          totalPages={paginate?.totalPages}
          currentPage={currentPage}
          goToPage={goToPage}
          noDataMessage={"You Currently don't have any History Feeds"}
        />
      </>
    </div>
  );
};

export default ActivityFeed;
