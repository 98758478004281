import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddHospitalForm from "../../../../components/forms/addHospitalForm";
import Header from "../../../../components/blocks/header";

import SearchQuery from "../../../../components/forms/searchQuery";
import Select from "../../../../components/common/select";

import { getPrivileges } from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";

import { Hospital } from "../../../../ts-utils/types";

import * as Dialog from "@radix-ui/react-dialog";

import {
  useAssignAgent,
  useGetAgentAdminHospitals,
  usePostCadre,
  useGetCadreList,
  // useGetAllAgents,
  useGetSalesAgent,
} from "../../../../hooks/agent/useAgent";
import { Link, useSearchParams } from "react-router-dom";
import { Eye, MoreVertical, Pencil, Equal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Button } from "../../../../@/components/ui/button";
import EditProspectForm from "../../../../components/forms/editProspectForm";
import ProspectedInfo from "../../../../components/modals/prospectedInfo";
import { SyncLoader } from "react-spinners";

import {
  useGetFacilityTypes,
  useGetFacilitySizes,
} from "../../../../hooks/useHospital";
import toastService from "../../../../services/toastService";
import ReactSelect from "../../../../components/common/ReactSelect";
import { PiGitPullRequestThin } from "react-icons/pi";

import { Renderable, ValueFunction, Toast } from "react-hot-toast";
import ProductTable, { ProductTableColumn } from "../../../../components/common/ProductTable";

const Hospitals = () => {
  const { user } = useGlobalContext();
  const privileges = getPrivileges();
  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const status = searchParams.get("status") || "";
  const search = searchParams.get("search") || "";
  const limit = searchParams.get("limit") || "20";

  const pageLimit = Number(limit) as number;
  const { hospitals, paginate, isPending } = useGetAgentAdminHospitals(
    page,
    pageLimit,
    status,
    search,
    getUserId(user?.id) as string
  );
  const { mutateAssignAgent, isPending: isAssignPending } = useAssignAgent();
  const { cadreList } = useGetCadreList();
  const { facilityTypes } = useGetFacilityTypes();
  const { facilitySizes } = useGetFacilitySizes();
  const { mutatePostCadre, isPending: isSubmitting } = usePostCadre();

  const [open, setOpen] = useState(false);
  const [openCadre, setOpenCadre] = useState(false);
  const [addHospital, setAddHospital] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);
  const [hospitalInfoToEdit, setHospitalInfoToEdit] = useState<Hospital | null>(
    null
  );
  const [cadre, setCadre] = useState({
    blood: cadreList?.[0]?.name,
    oxygen: cadreList?.[0]?.name,
    ref: "",
  });
  const [assign, setAssign] = useState<{
    id: string;
    name: string;
    username: string;
    state: string;
  } | null>(null);

  const filters = useMemo(() => {
    return {
      user_id: getUserId(user?.id) as string,
      page,
      search,
      status,
    };
  }, [getUserId, user?.id, page, search, status]);

  // const { agents } = useGetAllAgents(1, 1000, "", "", "0");
  const { mappedAgents } = useGetSalesAgent(assign?.state as string);

  const filterAgents = useMemo(
    () =>
      mappedAgents
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          const nameParts = agent.username.split(/(?=[A-Z])/);
          return {
            id: agent.id,
            name:
              nameParts.length > 1
                ? `${nameParts[0]} ${nameParts[1]}`
                : agent.username,
          };
        })
        .filter((v: { id: string; name: string }, i: number, a: any[]) => {
          return a.findIndex((t: { id: string }) => t.id === v.id) === i;
        })
        .filter((v: { name: string }) => v.name !== null)
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name)
        ) || [],
    [mappedAgents]
  );

  const filteredHospitals = useMemo(() => {
    return hospitals && !isPending
      ? hospitals.filter((hospital: { id: null }) => hospital.id !== null)
      : [];
  }, [hospitals, isPending]);

  const updateSearchParams = useCallback(
    (filters: { [x: string]: string | undefined }) => {
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          if (
            filters[key] === "" ||
            filters[key] === null ||
            filters[key] === undefined
          ) {
            searchParams.delete(key);
          } else {
            if (typeof filters[key] === "string") {
              searchParams.set(key, filters[key] as string);
            }
          }
        }
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const goToPage = useCallback(
    (page: number) => {
      updateSearchParams({ ...filters, page: page.toString() });
    },
    [filters, updateSearchParams]
  );

  const onAssignAgent = () => {
    const payload = {
      agent_id: selectedAgent,
      hospital_id: assign?.id,
      pitched: 0,
    };

    mutateAssignAgent(payload, {
      onSuccess: (data: {
        data: { message: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        console.log(data);
        toastService.success(data?.data?.message);
        setOpen(false);
      },
      onError: () => {
        setOpen(false);
      },
    });
  };

  const handleEditSelect = (hospital: Hospital): void => {
    setHospitalInfoToEdit(hospital);
  };

  const handleCategories = () => {
    const newCategories = [];

    const allHospitals = { id: "0", name: "All Hospitals" };
    const myHospitals = { id: "1", name: "My Hospitals" };

    if (privileges.allHospitals) newCategories.push(allHospitals);
    if (privileges.myHospitals) newCategories.push(myHospitals);
  };

  const handleSelectAgent = (item: any) => {
    setSelectedAgent(item);
  };

  const handleAssignAgent = (item: any) => {
    setAssign(item);
    setOpen(true);
  };

  const handleAddHospital = () => {
    setAddHospital((prev) => !prev);
  };

  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
  };

  const handleCadreRequest = (hospital: Hospital) => {
    setCadre({
      ...cadre,
      ref: hospital.ref_id,
    });

    setOpenCadre(true);
  };

  const onSubmitCadre = () => {
    console.log(cadre);
    mutatePostCadre(cadre, {
      onSuccess: (data: {
        data: { message: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        toastService.success(data?.data?.message);
        setOpenCadre(false);
      },
      onError: () => {
        setOpenCadre(false);
      },
    });
  };

  const getAgents = useMemo(
    () =>
      filteredHospitals
        .map((hospital: Hospital) => {
          return {
            id: hospital.user_id,
            name: hospital.username,
          };
        })
        .filter((v: { id: string; name: string }, i: number, a: any[]) => {
          return a.findIndex((t: { id: string }) => t.id === v.id) === i;
        })
        .filter((v: { name: string }) => v.name !== null)
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name)
        ) || [],
    [filteredHospitals]
  );

  useEffect(() => {
    if (getAgents?.length > 0) {
      setSelectedAgent(getAgents[0]?.id);
    }
  }, [getAgents]);

  useEffect(() => {
    handleCategories();

    // eslint-disable-next-line
  }, []);

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "id":
        return <p className="text-[#49209F] font-bold">{hospital?.id}</p>;
      case "name":
        return (
          <Link
            className="text-[#49209F] underline font-bold"
            to={`/hospitals/${hospital.id}`}
            state={{ status: hospital.status }}
          >
            {hospital?.name}
          </Link>
        );
      case "status":
        return (
          <button
            disabled
            className={"status " + hospital?.status?.toLowerCase?.()}
          >
            {hospital?.status}
          </button>
        );
      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => {
                    handleHospitalToView(hospital);
                  }}
                >
                  <Eye className="mr-2 h-4 w-4" /> View
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => {
                    handleCadreRequest(hospital);
                  }}
                >
                  <PiGitPullRequestThin className="mr-2 h-4 w-4" /> Cadre
                  Request
                </DropdownMenuItem>
                {privileges.createAgent && (
                  <>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => {
                        handleEditSelect(hospital);
                      }}
                    >
                      <Pencil className="mr-2 h-4 w-4" /> Edit
                    </DropdownMenuItem>

                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => {
                        handleAssignAgent(hospital);
                      }}
                    >
                      <Equal className="mr-2 h-4 w-4" /> Assign
                    </DropdownMenuItem>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      default:
        return hospital[column.accessor as keyof Hospital];
    }
  }

  const columns: ProductTableColumn[] = [
    { header: "Hospital Name", accessor: "name", className: "text-nowrap" },
    { header: "Email Address", accessor: "email", className: "text-nowrap" },
    { header: "Phone Number", accessor: "phone", className: "text-nowrap" },
    { header: "Status", accessor: "status", className: "text-nowrap" },
    { header: "Address", accessor: "address", className: "text-nowrap" },

    { header: "Contact", accessor: "contact_name", className: "text-nowrap" },
    {
      header: "Action",
      accessor: "action",
    },
  ];
  return (
    <React.Fragment>
      <>
        <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5"></div>
      </>
      {(isAssignPending || isPending) && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isAssignPending || isPending}
            className="relative"
            size={30}
          />
        </div>
      )}
      <Header title={`Hospitals - ${paginate?.totalItems ?? 0}`} />
      {privileges.createHospital && addHospital && (
        <AddHospitalForm handleClose={handleAddHospital} />
      )}
      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: { currentTarget: { value: string } }) => {
              updateSearchParams({
                ...filters,
                search: e.currentTarget.value,
                page: "1",
              });
            }}
          />

          <div className="right-options">
            <Select
              name="status"
              placeholder="Hospital Status"
              value={status}
              options={statusCategories}
              onChange={(e: { currentTarget: { value: string } }) => {
                updateSearchParams({
                  ...filters,
                  status: e.currentTarget.value,
                  page: "1",
                });
              }}
            />

            {/* {privileges.createHospital && (
               <Button
                      onClick={onAssignAgent}
                      className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg"
                    >
                      Add New Hospital
                    </Button>
              <Button
                // icon="plus"
                // label="Add New Hospital"
                onClick={handleAddHospital}
              />
            )} */}
          </div>
        </div>
        <div className="hospitals-container-bottom table-responsive">
          {" "}
          <>
            <div className="mx-auto px-4 md:px-8 mb-5">
              <>
                <ProductTable
                  data={filteredHospitals}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={+page}
                  goToPage={goToPage}
                />
              </>
            </div>
          </>
          <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Portal>
              <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
              <Dialog.Content
                aria-describedby={undefined}
                className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
              >
                <div className="bg-white rounded-md shadow-lg px-4 py-6">
                  <div className="flex items-center justify-end">
                    <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mx-auto"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Dialog.Close>
                  </div>
                  <div className="max-w-sm mx-auto space-y-3 ">
                    <Dialog.Title className="text-2xl font-bold text-gray-800 text-center ">
                      Assign Agent
                    </Dialog.Title>

                    <div className="space-y-5">
                      <p className="flex justify-between">
                        <span className="font-semibold inline-block">
                          Hospital Name:
                        </span>
                        <span className="inline-block">{assign?.name}</span>
                      </p>

                      <p className="flex justify-between">
                        <span className="font-semibold inline-block">
                          Current Agent:
                        </span>
                        <span className="inline-block">
                          {assign?.username ?? "N/A"}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold inline-block">
                        Select New Agent:
                      </span>
                      <div>
                        <ReactSelect
                          placeholder="Select New Agent"
                          options={filterAgents?.map(
                            (hospital: { id: string; name: string }) => ({
                              value: hospital.id,
                              label: hospital.name,
                            })
                          )}
                          onChange={handleSelectAgent}
                        />
                      </div>
                    </div>
                    <Button
                      onClick={onAssignAgent}
                      className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg"
                    >
                      Assign
                    </Button>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
          {/* Change Cadre */}
          <Dialog.Root open={openCadre} onOpenChange={setOpenCadre}>
            <Dialog.Portal>
              <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
              <Dialog.Content
                aria-describedby={undefined}
                className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
              >
                <div className="bg-white rounded-md shadow-lg px-4 py-6 ">
                  <div className="flex items-center justify-between mb-3">
                    <Dialog.Title className="text-xl font-bold text-gray-800 text-center ">
                      Change Cadre
                    </Dialog.Title>
                    <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mx-auto"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Dialog.Close>
                  </div>

                  <div className="space-y-3">
                    <div className="flex justify-between items-center">
                      <span className="font-semibold inline-block">
                        Select Blood Cadre:
                      </span>
                      <div>
                        <ReactSelect
                          defaultValue={cadre.blood}
                          options={
                            Array.isArray(cadreList)
                              ? cadreList?.map(
                                  (cadre: { id: string; name: string }) => ({
                                    value: cadre.name,
                                    label: cadre.name,
                                  })
                                )
                              : []
                          }
                          onChange={(value) =>
                            setCadre({
                              ...cadre,
                              blood: value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold inline-block">
                        Select Oxygen Cadre:
                      </span>
                      <div>
                        <ReactSelect
                          defaultValue={cadre.oxygen}
                          options={
                            Array.isArray(cadreList)
                              ? cadreList?.map(
                                  (cadre: { id: string; name: string }) => ({
                                    value: cadre.name,
                                    label: cadre.name,
                                  })
                                )
                              : []
                          }
                          onChange={(value) =>
                            setCadre({
                              ...cadre,
                              oxygen: value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <Button
                      disabled={isSubmitting}
                      onClick={onSubmitCadre}
                      className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg"
                    >
                      {isSubmitting ? "Submitting" : "Submit Request"}
                    </Button>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </div>{" "}
      {hospitalInfoToEdit && (
        <EditProspectForm
          selectedProspect={hospitalInfoToEdit}
          handleClose={() => handleEditSelect(null)}
        />
      )}{" "}
      {hospitalToView && user?.position !== "customer_success" && (
        <ProspectedInfo
          selectedProspect={hospitalToView}
          facilityType={facilityTypes}
          facilitySize={facilitySizes}
          handleClose={() => handleHospitalToView(null)}
        />
      )}
    </React.Fragment>
  );
};

const statusCategories = [
  { id: "new", name: "New" },
  { id: "prospect", name: "Prospect" },
  { id: "pitch", name: "Pitch" },
  { id: "onboard", name: "Onboard" },
  { id: "exodus", name: "Exodus" },
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "hibernate", name: "Hibernate" },
];

export default Hospitals;
